@import "main.css";
#navigation-bar-hide {
    opacity: 1 !important;
    top: 0 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nav-link{
color : #ffffff ! important;
}


.websitePageTitle-div {
display : none !important;
}

/*----------------------------------------  Boutons Réseaux-Sociaux */
.btnFB {
  position: fixed;
  bottom: 50px; right: 0;
  padding: 10px 3px 10px 20px;
  background: #E9B11A;
  color: white;
  text-decoration: none;
  border-radius : 30px 0 0 30px !important;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
z-index: 10;
}

.btnFB:hover {
  background: #EFD182;
color: white;
}


/*----------------------------------------  Bannière info 1 pas de défilement*/
.px-4.py-5.text-center.cta_banner.bg-sixthColor.position-relative > .background-image-div-opacity[style*="url(/assets/images/renovation-des-toitures-callac-9522925.webp)"] {
    background-attachment: unset !important;
}

/*----------------------------------------  Bannière info 2 pas de défilement*/
.px-4.py-5.text-center.cta_banner.bg-sixthColor.position-relative > .background-image-div-opacity[style*="url(/assets/images/pose-de-velux-guingamp-3399297.webp)"] {
    background-attachment: unset !important;
}


/*----------------------------------------  Padding titre seul*/
.titreSeul {
    padding-top: 70px;
}

@media all and (min-width: 990px) {
div.row-cols-1 >div.col {
width : 33.3333333333% !important;
}
}


div#couverture {
    margin-top: 5.5rem;
}

.row.align-items-center.g-lg-5.py-5.position-relative {
    margin-top: 4rem;
}